
import { computed, defineComponent, onMounted, ref } from "vue";
import ApiService from "@/core/services/ApiService";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import {useStore} from "vuex";

export default defineComponent({
  name: "Forwarder",

  setup() {
    const store = useStore();
    const u = localStorage.getItem("user") as any;
    const user = JSON.parse(u);
    const dataList = [
      {
        name: "forwarder_job_incoterm_type",
        route: "/forwarder_job_incoterm_type",
        icon: "las la-truck-loading",
        count: "",
        permission: "forwarder job incoterm type: index",
      },
      {
        name: "forwarder_job_status",
        route: "/forwarder_job_status",
        icon: "las la-exclamation-circle",
        count: "",
        permission: "forwarder job status: index",
      },
      {
        name: "forwarder_job_traffic_type",
        route: "/forwarder_job_traffic_type",
        icon: "las la-traffic-light",
        count: "",
        permission: "forwarder job traffic type: index",
      },
      {
        name: "forwarder_job_offer_type",
        route: "/forwarder_job_offer_type",
        icon: "lab la-buffer",
        count: "",
        permission: "forwarder offer type: index",
      },
      {
        name: "forwarder_job_shipped_via",
        route: "/forwarder_job_shipped_via",
        icon: "las la-walking",
        count: "",
        permission: "forwarder job shipped via: index",
      },
      {
        name: "forwarder_job_operation_type",
        route: "/forwarder_job_operation_type",
        icon: "las la-cogs",
        count: "",
        permission: "forwarder job operation type: index",
      },
      {
        name: "forwarder_job_language",
        route: "/forwarder_job_language",
        icon: "las la-language",
        count: "",
        permission: "forwarder job language: index",
      },
      {
        name: "forwarder_job_route_type",
        route: "/forwarder_job_route_type",
        icon: "las la-route",
        count: "",
        permission: "forwarder job route type: index",
      },
      {
        name: "forwarder_job_category",
        route: "/forwarder_job_category",
        icon: "las la-stream",
        count: "",
        permission: "forwarder job category: index",
      },
      {
        name: "forwarder_job_freight_type",
        route: "/forwarder_job_freight_type",
        icon: "las la-stream",
        count: "",
        permission: "forwarder job freight type: index",
      },
      {
        name: "forwarder_job_good_status",
        route: "/forwarder_job_good_status",
        icon: "las la-stream",
        count: "",
        permission: "forwarder job good status: index",
      },
      {
        name: "forwarder_job_groupage_type",
        route: "/forwarder_job_groupage_type",
        icon: "las la-stream",
        count: "",
        permission: "forwarder job groupage type: index",
      },
      {
        name: "forwarder_job_payment_method",
        route: "/forwarder_job_payment_method",
        icon: "las la-stream",
        count: "",
        permission: "forwarder job payment method: index",
      },
      {
        name: "forwarder_job_service_lines",
        route: "/forwarder_job_service_lines",
        icon: "las la-stream",
        count: "",
        permission: "forwarder job service line: index",
      },
      {
        name: "forwarder_job_offer_observation_templates",
        route: "/forwarder_job_offer_observation_templates",
        icon: "las la-stream",
        count: "",
        permission: "forwarder offer observation template: index",
      },
      {
        name: "forwarder_offer_status",
        route: "/forwarder_offer_status",
        icon: "las la-stream",
        count: "",
        permission: "forwarder offer status: index",
      },
      {
        name: "forwarder_air_way_bill",
        route: "/forwarder_air_way_bill",
        icon: "las la-stream",
        count: "",
        permission: "forwarder air way bill: index",
      },
      {
        name: "iforwarder_air_freight_company",
        route: "/forwarder_air_freight_company",
        icon: "las la-stream",
        count: "",
        permission: "forwarder air freight company: index",
      },
    ];
    const dataObject = ref<[] | any>([]);
    const search = ref("");
    const permissions = ref<[] | any>([]);

    const onSearch = () => {
      dataObject.value = dataList.filter(
        (x) =>
          JSON.stringify(x)
            .toLowerCase()
            .indexOf(search.value.toLowerCase()) !== -1
      );
    };

    const canViewCatalog = (item) => {
      if (JSON.stringify(user) !== "{}") {
        return permissions.value.filter((x) => x.name === item.permission).length > 0;
      }
      return false;
    };

    onMounted(() => {
      store.commit("setLoadingStatus", true);
      ApiService.get(`/api/permissions/me`)
          .then(({ data }) => {
            permissions.value = data;
          })
          .catch((error) => {
            console.log(error);
            store.commit("setLoadingStatus", false);
          });
      setCurrentPageBreadcrumbs("others", []);
      dataObject.value = dataList;
      ApiService.get("/api/catalogs/forwarder").then(({ data }) => {
        dataObject.value.forEach((item, index) => {
          item.count = data[index];
        });
        store.commit("setLoadingStatus", false);
      });
    });

    return {
      dataObject,
      dataList,
      search,
      canViewCatalog,
      onSearch,
      permissions,
    };
  },
});
